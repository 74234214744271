import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../../../../../../server/src/common/model/languages.enum';

@Injectable()
export class TranslationService {
  constructor(private _translate: TranslateService) {
  }

  public getAsync(key: string, interpolateParams?: Object): Promise<string> {
    return this._translate.get(key, interpolateParams).toPromise();
  }

  public setup() {
    this._translate.addLangs([Languages.English]);
    this._translate.setDefaultLang(Languages.English);

    const browserLang = this._translate.getBrowserLang();

    const chosenLanguage = Object.values(<any>Languages).includes(browserLang) ? browserLang : Languages.English;

    this._translate.use(chosenLanguage || Languages.English);
  }
}
