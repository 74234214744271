import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EmployeeLimitedDTO } from '../../../../../../server/src/companies/dto/employee.dto';
import { RouteNavigationService } from '../../routing/route-navigation.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-employee-tag',
  templateUrl: './employee-tag.component.html',
  styleUrls: ['./employee-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FaIconComponent,
    RouterLink,
  ],
})
export class EmployeeTagComponent implements OnInit, OnChanges {

  constructor(private _routeNavigationService: RouteNavigationService,
              private _changeDetector: ChangeDetectorRef) {
  }

  @Input()
  public employee?: EmployeeLimitedDTO;

  @Input()
  public disableNavigation: boolean = false;

  @Input()
  public display: 'normal' | 'big' = 'normal';

  public employeePageUrl?: string;

  ngOnInit(): void {
    if (!this.employee) {
      throw new Error('[employee] is required');
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.employee?.id) {
      this.employeePageUrl = this._routeNavigationService.getUrlForEmployeePage(this.employee.id);

      this._changeDetector.markForCheck();
    }
  }

  protected readonly faUser = faUser;
}
