@if (employee) {
  <div class="flex-row-center d-inline-flex vertical-align-middle">
    <div class="photo-container content-center">
      @if (employee.photo) {
        <img [src]="employee.photo.url" class="img-fluid rounded-circle">
      }
      @if (!employee.photo) {
        <fa-icon class="content-center" [icon]="faUser"></fa-icon>
      }
    </div>
    @if (!disableNavigation) {
      <a [routerLink]="employeePageUrl" class="ms-2">{{ employee.name }}</a>
    }
    @if (disableNavigation) {
      <span class="ms-2">{{ employee.name }}</span>
    }
  </div>
}
